/* eslint-disable */
var axios = require('axios')
import { userService } from '@/services/services'

export const innSolutionsServices = {
  getContactByID,
  updateContactByID,
  updateContactByCustomerDB,
  getInvoicePDF,
  getAPIUrl,
  getAllCustomers,}
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.status === 401) {
    userService.logout()
  }
  if (error.response.status === 401) {
    userService.logout()
  }
  return Promise.reject(error);
});

function getAPIUrl() {
  if (process.env.NODE_ENV === 'production') {
    return 'https://api.inn-solutions.ch'
  } else {
    return 'http://localhost:8000'
  }
}

/**
 * 
 * @param {String} jwt 
 * @returns 
 */
async function getContactByID(jwt, contactID) {
  return await axios({
    method: 'GET',
    url: `${getAPIUrl()}/innSolutions/getContactByID`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    params: {
      contactID: contactID,
    }
  })
    .then(res => {
      if (res.data.success) {
        return res.data.data
      } else {
        return Promise.reject()
      }
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}
/**
 * 
 * @param {String} jwt 
 * @returns 
 */
async function getAllCustomers(jwt) {
  return await axios({
    method: 'GET',
    url: `${getAPIUrl()}/innSolutions/getAllCustomers`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
  })
    .then(res => {
      if (res.data.success) {
        return res.data.companies
      } else {
        return Promise.reject()
      }
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}
/**
 * 
 * @param {String} jwt 
 * @returns 
 */
async function getInvoicePDF(jwt, periodID) {
  return await axios({
    method: 'GET',
    url: `${getAPIUrl()}/innSolutions/getInvoicePDF`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    params: {
      periodID: periodID,
    }
  })
    .then(res => {
      if (res.data.success) {
        return res.data
      } else {
        return Promise.reject()
      }
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}

async function updateContactByID(jwt, contactID, companyID, body) {
  return await axios({
    method: 'POST',
    url: `${getAPIUrl()}/innSolutions/updateContactAddress`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      contactID: contactID,
      companyID: companyID,
      body: body,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}
async function updateContactByCustomerDB(jwt, access_token, contactID, companyID,) {
  return await axios({
    method: 'POST',
    url: `${getAPIUrl()}/innSolutions/updateContactAddress`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      access_token: access_token,
      contactID: contactID,
      companyID: companyID,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}