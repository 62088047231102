/* eslint-disable */
import { config } from '@/config'
var axios = require('axios')
import { innSolutionsServices } from './innSolutionsServices'
import router from '@/router'

export const storeService = {
  getModules,
  bookSubscription,
  cancelSubscription,
  reactivateSubscription,
  sendInvoice,
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    userService.logout()
  }
  return Promise.reject(error);
});

/**
 * 
 * @param {String} jwt 
 * @returns 
 */
async function getModules(jwt) {
  return await axios({
    method: 'GET',
    url: `${innSolutionsServices.getAPIUrl()}/store/modules`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}
async function bookSubscription(jwt, companyID, moduleID, subscription, priceS, discount, isYearlyModel, services, subscriptionPrice) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/store/bookSubscription`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      companyID: companyID,
      moduleID: moduleID,
      subscription: subscription,
      priceS: priceS,
      discount: discount,
      isYearlyModel: isYearlyModel,
      services: services,
      subscriptionPrice: subscriptionPrice,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}

async function cancelSubscription(jwt, periodID) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/store/cancelSubscription`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      periodID: periodID,
    },
  })
    .then(res => {
      return Promise.resolve()
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}
async function reactivateSubscription(jwt, periodID) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/store/reactivateSubscription`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      periodID: periodID,
    },
  })
    .then(res => {
      return Promise.resolve()
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}

async function sendInvoice(jwt, companyID, invoiceID, document_nr) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/store/sendInvoiceEmail`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      companyID: companyID,
      invoiceID: invoiceID,
      document_nr: document_nr,
    },
  })
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject(err)
    })
}