<template>
  <AppSidebar v-if="company?.company" />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <CModal
      fullscreen
      :visible="storeVisible"
      @close="
        () => {
          toggleStore()
        }
      "
    >
      <CModalHeader class="text-center">Store</CModalHeader>
      <CModalBody><Store /></CModalBody>
    </CModal>
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer class="w-100" style="max-width: 1920px">
        <div v-if="loadingCompany" class="d-flex align-items-center">
          <strong>Loading...</strong>
          <div
            class="spinner-border ms-auto"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
        <div v-if="!loadingCompany">
          <AppTabs />
        </div>
        <br />
      </CContainer>
    </div>
    <AppFooter />
  </div>
  <AppAside />
</template>
<script>
import { CContainer } from '@coreui/vue-pro'
import AppAside from '@/components/AppAside.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppTabs from '@/components/AppTabs.vue'
import Store from '@/views/pages/Store.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
// import { bexioServices } from '@/services/bexioServices'

export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    AppFooter,
    AppHeader,
    AppTabs,
    AppSidebar,
    CContainer,
    Store,
  },
  data() {
    return {
      loadingCompany: false,
      modals: false,
      tabPaneActiveKey: 1,
    }
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapState('company', ['company']),
    ...mapState('store', ['modules']),
    ...mapState(['storeVisible']),
  },
  async beforeMount() {
    if (!this?.company && !this?.user) {
      this.logout()
    } else {
      this.getCompanyModules({
        jwt: this.user.token,
        companyID: this.company.company._id,
        modules: this.company.company.modules,
      })
        .then(() => {
          this.getCompanyCurrentPeriods({
            jwt: this.user.token,
            companyID: this.company.company._id,
          })
        })
        .catch(() => {
          return
        })
    }
  },
  async created() {
    await this.$loadLocaleMessages(this.$i18n, this.$i18n.locale)
  },
  async mounted() {
    if (this.storeVisible) {
      this.toggleStore()
    }
    await this.getModules(this.user.token)
  },
  methods: {
    ...mapGetters('tabs', ['apps']),
    ...mapActions(['toggleStore']),
    ...mapGetters('company', ['company']),
    ...mapActions('account', [
      'logout',
      'saveBexioToken',
      'saveBexioRefreshToken',
      'assignCompanyToAccount',
    ]),
    ...mapActions('store', ['getModules']),
    ...mapActions('company', [
      'saveCompany',
      'setCompanyLogo',
      'activateCompany',
      'getCompany',
      'getCompanys',
      'setCompany',
      'getCompanyModules',
      'getCompanyCurrentPeriods',
    ]),
    setHREF() {
      window.location.href = window.location.href.substring(
        0,
        window.location.href.indexOf('?'),
      )
    },
  },
}
</script>
