/* eslint-disable */

const state = { apps: [], tabPaneActiveKey: 0 }

const actions = {
  openApp({ dispatch, commit }, app) {
    commit('addAppToTabs', app)
  },
  closeApp({ dispatch, commit }, args) {
    const nApps = args.apps.filter((val, i) => (i === args.i ? false : true))
    commit('removeAppFromTabs', nApps)
  },
  changeIndex({ dispatch, commit }, index) {
    commit('updateTabPaneActiveKey', index)
  },
}

const getters = {
  apps(state) {
    return state.apps
  },
}

const mutations = {
  updateTabPaneActiveKey(state, index) {
    state.tabPaneActiveKey = index
  },
  addAppToTabs(state, app) {
    state.apps.push(app)
    if (state.tabPaneActiveKey < 0) {
      state.tabPaneActiveKey = 0
    } else {
      if (state.apps.length === 1) {
        state.tabPaneActiveKey = 0
      } else {
        state.tabPaneActiveKey = state.apps.length - 1
      }
    }
  },
  removeAppFromTabs(state, apps) {
    state.apps = apps
    if (state.tabPaneActiveKey === 0) {
    } else {
      state.tabPaneActiveKey--
    }
  },
}

export const tabs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
