/* eslint-disable */
import { createStore } from 'vuex'
import { account } from '@/store/account.module'
import { company } from '@/store/company.module'
import { tabs } from '@/store/tabs.module'
import { store } from '@/store/store.module'
import { spl } from '@/store/spl.module'
import localForage from 'localforage'
import VuexPersist from 'vuex-persist'

const vuexStorage = new VuexPersist({
  key: 'sourcelink',
  storage: localForage,
  // modules: ['account'], //only save user module
  asyncStorage: true,
  reducer: (state) => JSON.parse(JSON.stringify(state)),
})

export default createStore({
  plugins: [vuexStorage.plugin],
  state: {
    storeVisible: false,
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
  },

  mutations: {
    toggleStore(state) {
      state.storeVisible = !state.storeVisible
    },
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  getters: {
    sidebarVisible(state) {
      return state.sidebarVisible
    },
  },
  actions: {
    toggleStore({ dispatch, commit }) {
      commit('toggleStore')
    },
  },
  modules: {
    account: account,
    company: company,
    store: store,
    tabs: tabs,
    spl: spl,
  },
})
