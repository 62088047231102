import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    // redirect: '/',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/company',
        name: 'Company',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Company" */ '@/views/pages/Company.vue'),
      },
      {
        path: '/store',
        name: 'Store',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "store" */ '@/views/pages/Store.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },
  {
    path: '/sso',
    name: 'SSO',
    component: () => import('@/views/pages/SSO'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/pages/Register'),
  },
  {
    path: '/accountSettings',
    name: 'AccountSettings',
    component: () => import('@/views/account/AccountSettings.vue'),
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})
// eslint-disable-next-line
const waitForStorageToBeReady = async (to, from) => {
  // console.log('to: ', to)
  // console.log('from: ', from)

  if (to.fullPath === '/login') {
    return true
  }
  if (to.fullPath === '/register') {
    return true
  }
  if (to.fullPath === '/sso') {
    return true
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await store.restored
    if (to.fullPath === '/') {
      if (store.state.account.status.loggedIn) {
        return true
      } else {
        return '/login'
      }
    }
    if (store.state.account.status.loggedIn) {
      return true
      // if (
      //   !store.state.account.user?.companys ||
      //   store.state.account.user?.companys.length === 0
      // ) {
      //   console.log('hat keine Firma')
      //   if (to.name === 'Company') {
      //     if (!from?.name) {
      //       return true
      //     }
      //     to.fullPath = `/company?token=${store.state.account.user.access_token}`
      //   }
      //   console.log(to.name !== 'Company' && to.name !== 'Store')
      //   if (to.name !== 'Company' && to.name !== 'Store') {
      //     return `/company?token=${store.state.account.user.access_token}`
      //   } else return true
      // }
      // if (
      //   store.state.account.user?.companys.length >= 1 &&
      //   store.state.account.user?.companys[0] !== ''
      // ) {
      //   if (store.state.account.user?.companys.length === 1) {
      //     return '/dashboard'
      //   }
      //   if (store.state.account.user?.companys.length >= 1) {
      //     if (to.name === 'Company') {
      //       if (!from?.name) {
      //         return true
      //       }
      //       to.fullPath = `/company?token=${store.state.account.user.access_token}`
      //     }
      //     console.log(to.name !== 'Company' && to.name !== 'Store')
      //     if (to.name !== 'Company' && to.name !== 'Store') {
      //       return `/company?token=${store.state.account.user.access_token}`
      //     } else return true
      //   }
      //   console.log('hat Firma')
      //   if (to.name === 'Dashboard') {
      //     if (!from?.name) {
      //       return true
      //     }
      //   }
      //   return '/dashboard'
      // }
    }
    return false
  } else {
    return true
  }
}
router.beforeEach(waitForStorageToBeReady)

export default router
