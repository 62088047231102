/* eslint-disable */
import { storeService } from '@/services/storeServices'
const state = { modules: [] }

const actions = {
  async getModules({ dispatch, commit }, jwt) {
    return new Promise(async (resolve, reject) => {
      storeService.getModules(jwt).then(
        (modules) => {
          modules = modules.sort((a, b) => { return a.sortValue - b.sortValue })
          //TODO sort views
          commit('saveModules', modules)
          resolve(modules)
        },
        (error) => {
          // commit('loginFailure', {}) TODO commit error
          reject(error)
        },
      )
    })
  },
  async cancelSubscription({ dispatch, commit }, data) {
    return new Promise(async (resolve, reject) => {
      storeService.cancelSubscription(data.jwt, data.periodID)
        .then(
          () => {
            resolve()
          },
        )
        .catch(
          (err) => {
            reject()
          })
    })
  },
  async reactivateSubscription({ dispatch, commit }, data) {
    return new Promise(async (resolve, reject) => {
      storeService.reactivateSubscription(data.jwt, data.periodID)
        .then(
          () => {
            resolve()
          },
        )
        .catch(
          (err) => {
            reject()
          })
    })
  },
}

const getters = {
  gModules(state) { return state.modules },
}

const mutations = {
  saveModules(state, modules) {
    state.modules = modules
  },
}

export const store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}