/* eslint-disable */
import { config } from '@/config'
import { userService } from '@/services/services'
import { innSolutionsServices } from './innSolutionsServices'
var axios = require('axios')
import router from '@/router'

export const settingsServices = {
  getModuleSettings,
  saveModuleSettings,
  sDefaultModuleSettings,
  updateModuleSettings,
  uGASLanguage,
  uCustomLabelDocuments,
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.status === 401) {
    userService.logout()
  }
  if (error.response.status === 401) {
    userService.logout()
  }
  return Promise.reject(error);
});

/**
 * 
 * @param {String} jwt 
 * @returns 
 */
async function getModuleSettings(jwt, moduleID, companyID) {
  return await axios({
    method: 'GET',
    url: `${innSolutionsServices.getAPIUrl()}/settings/module`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    params: {
      moduleID: moduleID,
      companyID: companyID,
    }
  })
    .then(res => {
      if (res.data.success) {
        return res.data.data.module
      } else {
        return Promise.reject()
      }
    })
    .catch(err => {
      console.log('err', err)
      return Promise.reject()
    })
}

async function sDefaultModuleSettings(jwt, modules, companyID, companyAddress) {
  console.log('sDefaultModuleSettings', modules, companyID, companyAddress)
  await Promise.all(
    modules.map(async (e) => {
      axios({
        method: 'POST',
        url: `${innSolutionsServices.getAPIUrl()}/settings/sDefaultModuleSettings`,
        headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
        data: {
          companyID: companyID,
          moduleID: e,
          companyAddress: companyAddress,
        },
      })
    })
  )
    .then(res => {
      console.log('Promise All', res)
      return res
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}
async function saveModuleSettings(jwt, moduleID, companyID, companyAddress) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/settings/sModule`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      companyID: companyID,
      moduleID: moduleID,
      companyAddress: companyAddress,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}

async function updateModuleSettings(jwt, settingID, updateVals) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/settings/uModule`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      settingID: settingID,
      updateVals: updateVals,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}

async function uGASLanguage(jwt, settingID, lang) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/settings/uSPLGASLanguage`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      settingID: settingID,
      lang: lang,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}

async function uCustomLabelDocuments(jwt, settingID, customLabelDocuments) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/settings/uCustomLabelDocuments`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      settingID: settingID,
      customLabelDocuments: customLabelDocuments,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.log('err', err)
      return err
    })
}