<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink v-if="isActive" href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink v-if="isActive" href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink v-if="isActive" href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            v-if="isActive"
            id="btn-light-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CNavItem class="text-center">
            <CHeaderBrand>
              {{ company.company.name }}
            </CHeaderBrand>
          </CNavItem>
          <CFormCheck
            v-if="isActive"
            id="btn-dark-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
      <CHeaderNav class="me-0">
        <AppHeaderDropdownNotif v-if="false" />
        <AppHeaderDropdownTasks v-if="false" />
        <AppHeaderDropdownMssgs v-if="false" />
      </CHeaderNav>
      <CHeaderNav v-if="showStoreBtn" class="me-4">
        <CButton color="success" @click="toggleStore()">Upgrade</CButton>
      </CHeaderNav>
      <!-- <CHeaderToggler class="px-md-0 me-md-3">
        <CIcon
          size="sm"
          v-if="false"
          icon="cil-applications-settings"
          @click="$store.commit('toggleAside')"
        />
      </CHeaderToggler> -->
      <CHeaderNav class="ms-1 me-1">
        <CFormSelect
          v-if="showLang()"
          aria-label="Select language"
          :multiple="false"
          v-model="localeVal"
          @update:modelValue="
            (val) => {
              setLocale(val)
            }
          "
        >
          <option
            v-for="(locale, index) in locales"
            :key="index"
            :value="locale.toLowerCase()"
          >
            {{ locale }}
          </option>
        </CFormSelect>
      </CHeaderNav>
    </CContainer>
    <!-- <CHeaderDivider /> -->
    <!-- <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
  </CHeader>
</template>

<script>
// import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
  },
  data() {
    return {
      logo: '',
      isActive: false,
      locales: ['DE', 'EN', 'IT', 'FR'],
      localeVal: '',
      isStoreOpen: false,
      showStoreBtn: false,
      currentPeriod: {},
    }
  },
  computed: {
    ...mapState('company', ['company']),
  },
  async mounted() {
    this.locale = this.$i18n.locale
    this.$store.watch(
      (state) => state.company.periods,
      (value) => {
        if (value) {
          console.log('$store.watch company.periods', value)
        }
      },
    )
    // this.$store.watch(
    //   (state) => state.company.company.isActive,
    //   (value) => {
    //     if (value) {
    //       this.isActive = value.val
    //     }
    //   },
    // )
  },
  methods: {
    ...mapActions(['toggleStore']),
    ...mapActions('company', [
      'checkIfPeriodNotOverdue',
      'getCurrentPeriod',
      'getCompanyCurrentPeriods',
    ]),
    setLocale(val) {
      this.$i18n.locale = val
    },
    showLang() {
      return false
      // console.log('Production ENV', process.env.NODE_ENV)
      // console.log('showLang:', process.env.NODE_ENV === 'development')
      // return process.env.NODE_ENV === 'development'
    },
  },
}
</script>
