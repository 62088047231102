/* eslint-disable */
import { userService } from '@/services/services'

const state = { status: { loggedIn: false, loggingIn: false }, user: {} }

const actions = {
  async ssoLogin({ dispatch, commit }, data) {
    return new Promise(async (resolve, reject) => {
      userService.ssoLogin(data.bxToken, data.login_id, data.company_id, data.user_id, data.company_user_id).then(
        (token) => {
          resolve(token)
        },
        (error) => {
          commit('loginFailure', {})
          reject(error)
        },
      )
    })
  },
  async ssobxInitAPI({ dispatch, commit }) {
    console.log('ssobxInitAPI')
    return new Promise(async (resolve, reject) => {
      userService.ssobxInitAPI().then(
        (token) => {
          resolve()
        },
        (error) => {
          commit('loginFailure', {})
          reject(error)
        },
      )
    })
  },
  async checkUserCompanyConnection({ dispatch, commit }, data) {
    return new Promise(async (resolve, reject) => {
      if (data.user.companies.length === 0) {
        await userService.assignCompanyToUser(data.token, data.companyID)
          .then((resp) => {
            return resolve()
          })
          .catch((err) => {
            return reject()
          }) // TODO catch
      } else {
        var addCommpany = true
        for (let i = 0; i < data.user.companies.length; i++) {
          if (data.user.companies[i] === data.companyID) {
            addCommpany = false
          }
        }
        if (addCommpany) {
          await userService.assignCompanyToUser(data.token, data.companyID)
            .then(() => {
              return resolve()
            })
            .catch(() => {
              return reject()
            }) // TODO catch
        } else {
          return resolve()
        }
      }
    })
  },
  async ssoGetUser({ dispatch, commit }, data) {
    return new Promise(async (resolve, reject) => {
      userService.ssoGetUser(data.token, data.user_id).then(
        (user) => {
          user.token = data.token
          commit('loginSuccess', user)
          resolve(user)
        },
        (error) => {
          commit('loginFailure', {})
          reject(error)
        },
      )
    })
  },
  async login({ dispatch, commit }, loginUser) {
    return new Promise(async (resolve, reject) => {
      userService.login(loginUser).then(
        (token) => {
          // user = JSON.parse(localStorage.getItem('user'))
          userService.getUser(token).then((user2) => {
            user2.token = token
            console.log(user2);
            commit('loginSuccess', user2)
            resolve(user2)
          }
          ).catch((error) => {
            commit('loginFailure', {})
            reject(error)
            // commit('loginFailure', error)
            // dispatch('alert/error', error, { root: true })
          })
        },
        (error) => {
          commit('loginFailure', {})
          reject(error)
        },
      )
    })
  },
  async updateUserProfile({ dispatch, commit }, data) {
    return new Promise(async (resolve, reject) => {
      console.log('data', data);
      userService.updateUserProfile(data.token, data.userID, data.user.email, data.user.name, data.user.surName, data.user.AddrTitle, data.user.language).then((res) => {
        if (res.success) {
          userService.getUser(data.token).then((user2) => {
            console.log('user2', user2);
            user2.token = data.token
            user2.access_token = data.bxToken
            user2.refresh_token = data.refresh_token
            commit('loginSuccess', user2)
            resolve(user2)
          }
          ).catch((error) => {
            reject(error)
            // commit('loginFailure', error)
            // dispatch('alert/error', error, { root: true })
          })
        } else {
          reject(error)
        }
      }
      ).catch((error) => {
        reject(error)
        // commit('loginFailure', error)
        // dispatch('alert/error', error, { root: true })
      })
    })
  },
  async getUser({ dispatch, commit }, token) {
    return new Promise(async (resolve, reject) => {
      commit('getUser', { token })
      userService.getUser(token).then((user2) => {
        user2.token = token
        console.log(user2);
        dispatch('loginSuccess', user2)
        resolve(user2)
      }
      ).catch((error) => {
        commit('loginFailure', {})
        reject(error)
        // commit('loginFailure', error)
        // dispatch('alert/error', error, { root: true })
      })
    })
  },
  bxInitAPI({ dispatch, commit }, token) {
    console.log('dispatch bxInitAPI')
    return new Promise(async (resolve, reject) => {
      userService.bxInitAPI(token).then(
        (url) => {
          window.open(url, '_self')
          resolve()
        },
        (error) => {
          reject(error)
        },
      )
    })
  },
  logout({ commit }) {
    userService.logout()
    commit('logout')
  },
  reset({ commit }) {
    console.log('reset')
    commit('logout')
  },
  saveBexioToken({ commit }, acToken) {
    commit('setBexioToken', acToken)
  },
  saveBexioRefreshToken({ commit }, rfToken) {
    commit('setBexioRefreshToken', rfToken)
  },
  assignCompanyToAccount({ commit }, args) {
    return new Promise((resolve, reject) => {
      console.log('assignCompanyToAccount')
      console.log('args', args)
      userService.assignCompanyToUser(args.token, args.companyID)
        .then(() => {
          commit('assignCompanyToAccount', args.companyID)
          return resolve()
        })
        .catch(() => {
          return reject()
        }) // TODO catch
    })
  },
  register({ dispatch, commit }, user) {
    commit('registerRequest', user)
    userService.register(user)
      .then(
        (user) => {
          commit('registerSuccess', user)
          // router.push('/login')
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Registration successful', { root: true })
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const getters = {
  /* Partially Implemented not for production use */
  loggedIn: state => !!state.token

}

const mutations = {
  loginRequest(state, user) {
    state.status = { loggedIn: false, loggingIn: true }
    state.user = user
    state.token = user.token
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true, loggingIn: false }
    state.user = user
  },
  setBexioToken(state, access_token) {
    state.user.access_token = access_token
  },
  setBexioRefreshToken(state, refresh_token) {
    state.user.refresh_token = refresh_token
  },
  assignCompanyToAccount(state, companyID) {
    state.user.companys.push(companyID)
  },
  loginFailure(state) {
    state.status = { loggedIn: false, loggingIn: false }
    state.user = null
  },
  logout(state) {
    state.status = { loggedIn: false, loggingIn: false }
    state.user = null
  },
  registerRequest(state, user) {
    state.status = { registering: true }
  },
  registerSuccess(state, user) {
    state.status = {}
  },
  registerFailure(state, error) {
    state.status = {}
  }
}

export const account = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}