/* eslint-disable */
const state = { modules: [] }

const actions = {
  async getModules({ dispatch, commit }, jwt) {
    return new Promise(async (resolve, reject) => {
      storeService.getModules(jwt).then(
        (modules) => {
          modules = modules.sort((a, b) => { return a.sortValue - b.sortValue })
          //TODO sort views
          commit('saveModules', modules)
          resolve(modules)
        },
        (error) => {
          // commit('loginFailure', {}) TODO commit error
          reject(error)
        },
      )
    })
  },
}

const getters = {
  gModules(state) { return state.modules },
}

const mutations = {
  saveModules(state, modules) {
    state.modules = modules
  },

}

export const spl = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}