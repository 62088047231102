<template>
  <CContainer>
    <h2>Abo auswählen für {{ company.company.name }}</h2>
    <br />
    <CRow :xs="{ cols: 1, gutter: 4 }" :md="{ cols: 2 }">
      <CCol xs v-for="(module, index) in gModules" :key="module._id">
        <div v-if="module.showStore" class="p-4 border bg-light">
          <CCard v-if="!subscriptionLoading" style="width: 100%">
            <CCardImage orientation="top" :src="module.base64Img" thumbnail />
            <CCardBody>
              <CCardTitle class="text-center">{{ module.name }}</CCardTitle>
              <CCardText>
                {{ module.cardText }}
              </CCardText>
              <CListGroup flush style="padding-left: 10px">
                <CListGroupItem
                  v-for="point in module.cardPositivePoints"
                  :key="point"
                  style="padding-left: 0px"
                >
                  <CIcon :icon="cilCheckCircle" style="color: green" />
                  {{ point }}</CListGroupItem
                >
              </CListGroup>
              <div class="d-grid gap-2 col-12 mx-auto">
                <CButton color="info" variant="ghost"
                  >Ich möchte mehr darüber erfahren!</CButton
                >
                <CButton
                  color="success"
                  variant="ghost"
                  @click="
                    () => {
                      mMI = index
                      chSblModel = gModules[index].subsciptionsModels
                      isModalActive = true
                    }
                  "
                  >Abos auswählen</CButton
                >
              </div>
            </CCardBody>
          </CCard>
          <CCard v-if="subscriptionLoading" style="width: 18rem">
            <CCardImage
              component="svg"
              orientation="top"
              width="100%"
              height="162"
              role="img"
              aria-label="Placeholder"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title
              ><rect width="100%" height="100%" fill="#868e96"></rect>
            </CCardImage>
            <CCardBody>
              <CCardTitle v-c-placeholder="{ animation: 'glow', xs: 7 }">
                <CPlaceholder :xs="6" />
              </CCardTitle>
              <CCardText v-c-placeholder="{ animation: 'glow' }">
                <CPlaceholder :xs="7" />
                <CPlaceholder :xs="4" />
                <CPlaceholder :xs="4" />
                <CPlaceholder :xs="6" />
                <CPlaceholder :xs="8" />
              </CCardText>
              <CButton
                v-c-placeholder="{ xs: 6 }"
                color="primary"
                aria-hidden="true"
                disabled
                href="#"
                tabindex="-1"
              ></CButton>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
    </CRow>
    <CModal
      fullscreen
      :visible="isModalActive"
      @close="
        () => {
          isModalActive = false
        }
      "
    >
      <CModalHeader>
        <CModalTitle>{{ gModules[mMI].names[$i18n.locale] }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer v-if="purchaseLoading" class="h-100">
          <CRow class="align-items-end" style="height: 50%">
            <CCol class="text-center"
              ><CSpinner v-if="purchaseLoading" color="success"
            /></CCol>
          </CRow>
          <CRow>
            <CCol class="text-center"
              >{{ gModules[mMI].names[$i18n.locale] }} wird bestellt...
              Danke!</CCol
            >
          </CRow>
        </CContainer>
        <CContainer v-if="purchasedSuccessful" class="h-100">
          <CRow class="align-items-end h-50">
            <CCol class="text-center h-100">
              <CCard class="w-80 bg-success mt-5">
                <CIcon
                  :icon="cilCheckCircle"
                  custom-class-name="successIcon m-auto pt-4"
                />
                <CCardBody class="mt-4">
                  <CCardTitle style="color: white"
                    >Besten Dank für Ihren Kauf!</CCardTitle
                  >
                  <CCardText style="color: white"
                    >Das Abo {{ gModules[mMI].names[$i18n.locale] }} wurde
                    erfolgreich aufgeschaltet für {{ company.company.name }}🥳
                  </CCardText>
                  <CButton
                    class="mt-5"
                    style="color: white"
                    @click="
                      () => {
                        subscriptionLoading = false
                        purchaseLoading = false
                        purchasedSuccessful = false
                        toggleStore()
                        getCompanyCurrentPeriods()
                        getCompanyModules()
                        isModalActive = false
                        forceRerender()
                      }
                    "
                    >Los geht's!</CButton
                  >
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow> </CRow>
        </CContainer>
        <CContainer v-if="!purchaseLoading && !purchasedSuccessful">
          <CRow :xs="{ cols: 1 }" :sm="{ cols: 2, gutter: 2 }">
            <CCol sm="8">
              <CAlert
                color="danger"
                v-if="!validateEmail(company.company.email)"
              >
                <CAlertHeading>
                  Die E-Mail Adresse "{{ company.company.email }}" ist nicht
                  gültig! Bitte anpassen</CAlertHeading
                >
              </CAlert>
              <CAccordion :activeItemKey="2">
                <CAccordionItem :item-key="1">
                  <CAccordionHeader
                    >{{ company.company.name }} Adresse
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CForm class="row g-3">
                      <CCol xs="12">
                        <CFormLabel for="AddressName">Name</CFormLabel>
                        <CFormInput
                          disabled
                          type="email"
                          id="AddressName"
                          placeholder="Firmenname"
                          :modelValue="company.company.name"
                        />
                      </CCol>
                      <CCol xs="12">
                        <CFormLabel for="AddressStreet">Strasse</CFormLabel>
                        <CFormInput
                          disabled
                          id="AddressStreet"
                          placeholder="Strasse 1"
                          :modelValue="company.company.address"
                        />
                      </CCol>
                      <CCol md="6">
                        <CFormLabel for="AddressCity">Ort</CFormLabel>
                        <CFormInput
                          disabled
                          id="AddressCity"
                          placeholder="Ort"
                          :modelValue="company.company.city"
                        />
                      </CCol>
                      <CCol md="2">
                        <CFormLabel for="inputpc">Postfach</CFormLabel>
                        <CFormInput
                          disabled
                          id="inputpc"
                          :modelValue="company.company.postcode"
                        />
                      </CCol>
                      <CCol md="4">
                        <CFormLabel for="AddressCountry">Land</CFormLabel>
                        <CFormInput
                          disabled
                          id="AddressCountry"
                          placeholder="Land"
                          :modelValue="company.company.country_name"
                        />
                      </CCol>
                      <CCol xs="12">
                        <CFormLabel for="AddressNumber">Nummer</CFormLabel>
                        <CFormInput
                          disabled
                          id="AddressNumber"
                          placeholder="+41 62 012 345 56"
                          :modelValue="company.company.phone_fixed"
                        />
                      </CCol>
                      <CCol xs="12">
                        <CFormLabel for="AddressEmail">E-Mail</CFormLabel>
                        <CFormInput
                          disabled
                          id="AddressEmail"
                          :modelValue="company.company.email"
                        />
                      </CCol>
                      <CCol xs="12">
                        <CFormCheck
                          type="checkbox"
                          id="abwAddr"
                          label="Abweichende Rechnungsadresse"
                          :modelValue="address.abwAddr"
                          @update:modelValue="
                            (value) => {
                              this.address.abwAddr = value
                            }
                          "
                        />
                      </CCol>
                    </CForm>
                    <CForm class="row g-3" v-if="address.abwAddr">
                      <CCol xs="12">
                        <CFormLabel for="AddressName">Name</CFormLabel>
                        <CFormInput
                          type="email"
                          id="AddressName"
                          placeholder="Firmenname"
                          :modelValue="address.name"
                          @update:modelValue="
                            (value) => {
                              this.address.name = value
                            }
                          "
                        />
                      </CCol>
                      <CCol xs="12">
                        <CFormLabel for="AddressStreet">Strasse</CFormLabel>
                        <CFormInput
                          id="AddressStreet"
                          placeholder="Strasse 1"
                          :modelValue="address.street"
                          @update:modelValue="
                            (value) => {
                              this.address.street = value
                            }
                          "
                        />
                      </CCol>
                      <CCol md="6">
                        <CFormLabel for="AddressCity">Ort</CFormLabel>
                        <CFormInput
                          id="AddressCity"
                          placeholder="Ort"
                          :modelValue="address.city"
                          @update:modelValue="
                            (value) => {
                              this.address.city = value
                            }
                          "
                        />
                      </CCol>
                      <CCol md="2">
                        <CFormLabel for="inputpc">Postfach</CFormLabel>
                        <CFormInput
                          id="inputpc"
                          :modelValue="address.postalcode"
                          @update:modelValue="
                            (value) => {
                              this.address.postalcode = value
                            }
                          "
                        />
                      </CCol>
                      <CCol md="4">
                        <CFormLabel for="AddressCountry">Land</CFormLabel>
                        <CFormInput
                          id="AddressCountry"
                          placeholder="Land"
                          :modelValue="address.country"
                          @update:modelValue="
                            (value) => {
                              this.address.country = value
                            }
                          "
                        />
                      </CCol>
                      <CCol>
                        <CFormLabel for="AddressNumber">Nummer</CFormLabel>
                        <CFormInput
                          id="AddressNumber"
                          placeholder="+41 62 012 345 56"
                          :modelValue="address.num"
                          @update:modelValue="
                            (value) => {
                              this.address.num = value
                            }
                          "
                        />
                      </CCol>
                      <CCol xs="12">
                        <CFormLabel for="AddressEmail">E-Mail</CFormLabel>
                        <CFormInput
                          id="AddressEmail"
                          placeholder="company@domain.ch"
                          :modelValue="this.address.email"
                          @update:modelValue="
                            (value) => {
                              this.address.email = value
                            }
                          "
                        />
                      </CCol>
                    </CForm>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem :item-key="2">
                  <CAccordionHeader>
                    {{ gModules[mMI].names[$i18n.locale] }}</CAccordionHeader
                  >
                  <CAccordionBody>
                    <CTable responsive>
                      <CTableCaption
                        >1 Monat = 30 Tage<br />
                        30 Tage Netto<br />Rechnung per E-Mail<br /><br />
                      </CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell scope="col">Paket</CTableHeaderCell>
                          <CTableHeaderCell
                            v-for="sub in gModules[mMI].subsciptionsModels"
                            :key="sub"
                            scope="col"
                            >{{ sub.name }}</CTableHeaderCell
                          >
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        <CTableRow
                          v-for="(h, index) in gModules[mMI]
                            .subsciptionsModels[0].table"
                          :key="h"
                        >
                          <CTableHeaderCell scope="row">
                            {{ h.header }}
                          </CTableHeaderCell>
                          <template
                            v-for="t in gModules[mMI].subsciptionsModels"
                            :key="t"
                          >
                            <CTableDataCell>{{
                              t.table[index].value
                            }}</CTableDataCell>
                          </template>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem :item-key="3">
                  <CAccordionHeader> Zusatzleistungen</CAccordionHeader>
                  <CAccordionBody>
                    <CRow :xs="{ cols: 1, gutter: 2 }" :md="{ cols: 2 }">
                      <CCol
                        xs
                        v-for="service in gModules[mMI].services.filter((s) => {
                          if (s.isActive) {
                            return s
                          }
                        })"
                        :key="service"
                      >
                        <CCard class="h-100">
                          <CCardBody>
                            <CCardTitle>{{ service.name }}</CCardTitle>
                            <CCardText style="height: 6rem">
                              {{ service.text }}
                            </CCardText>
                            <hr />
                            {{ service.price }} CHF
                          </CCardBody>
                        </CCard>
                      </CCol>
                    </CRow>
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
              <br />
              <p v-show="showSalesEMail" class="text-center">
                <a href="mailto:sales@inn-solutions.ch"
                  >Gibt es Fragen oder Unklarheiten?</a
                >
              </p>
            </CCol>
            <CCol sm="4"
              ><CCard class="h-100" style="">
                <CCardHeader class="text-center">Bestellübersicht</CCardHeader>
                <CCardBody>
                  <CForm>
                    <CRow>
                      <CCardTitle class="text-center"
                        >Verrechnungsart</CCardTitle
                      >
                      <CCol sm="10">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="Jahresrechnung"
                          :value="true"
                          v-model="isYearlyModel"
                          @change="
                            (value) => {
                              setYearly(true)
                              calculatePriceS(subscription)
                            }
                          "
                        />
                        <label
                          class="form-check-label"
                          style="padding-left: 5px"
                          for="Jahresrechnung"
                          >Jahresrechnung
                          <span style="color: red">(5% Rabatt)</span></label
                        ><br />
                        <input
                          type="radio"
                          id="Monatsrechnung"
                          class="form-check-input"
                          :value="false"
                          v-model="isYearlyModel"
                          @change="
                            (value) => {
                              setYearly(false)
                              calculatePriceS(subscription)
                            }
                          "
                          checked
                        />
                        <label
                          class="form-check-label"
                          style="padding-left: 5px"
                          for="Monatsrechnung"
                          >Monatsrechnung</label
                        >
                      </CCol>
                    </CRow>
                    <hr />
                    <CRow class="mb-3">
                      <CCardTitle class="text-center"
                        >Paket auswählen</CCardTitle
                      >
                      <CFormFloating>
                        <CFormSelect
                          id="subscription"
                          aria-label="Paket auswählen"
                          :modelValue="subscription"
                          @update:modelValue="
                            (val) => {
                              subscription = val
                              calculatePriceS(val)
                            }
                          "
                        >
                          <option
                            v-for="(sub, index) in gModules[mMI]
                              .subsciptionsModels"
                            :key="sub"
                            :value="index"
                          >
                            {{ sub.name }}
                          </option>
                        </CFormSelect>
                      </CFormFloating>
                    </CRow>
                    <hr />
                    <CRow>
                      <fieldset class="row mb-3">
                        <CCardTitle class="text-center"
                          >Zusatzleistungen</CCardTitle
                        >
                        <CCol sm="10">
                          <div
                            :key="index"
                            v-for="(service, index) in gModules[
                              mMI
                            ].services.filter((s) => {
                              if (s.isActive) {
                                return s
                              }
                            })"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :id="service.name"
                              v-model.lazy="service.selected"
                              @change="calculatePriceS(subscription)"
                            />
                            <label
                              :for="service.name"
                              style="padding-left: 5px"
                              >{{ service.name }}</label
                            >
                          </div>
                        </CCol>
                      </fieldset>
                    </CRow>
                    <hr />
                    <CRow>
                      <CCardTitle class="text-center"
                        >Zusammenfassung</CCardTitle
                      >
                      <CContainer>
                        <CRow>
                          <CCol xs="6"> {{ subscriptionName }} Paket </CCol>
                          <CCol class="text-end" xs="6">
                            {{
                              subscriptionPrice === 0
                                ? '0.00'
                                : subscriptionPrice
                            }}
                            CHF
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol
                            v-if="checkSelectedServices(gModules[mMI].services)"
                            xs="6"
                          >
                            Zusatzleistung
                          </CCol>
                          <CCol
                            v-if="checkSelectedServices(gModules[mMI].services)"
                            xs="6"
                            class="text-end"
                          >
                            {{ this.servicesPrice }} CHF
                          </CCol>
                        </CRow>
                        <hr />
                        <CRow>
                          <CCol xs="6"> Gesamtsumme </CCol>
                          <CCol class="text-end" xs="6">
                            {{ Number(priceS).toFixed(2) }} CHF
                          </CCol>
                        </CRow>
                        <br />
                        <CRow>
                          <CCol xs="6">
                            <CFormCheck
                              checkbox
                              id="AGBs"
                              :modelValue="agb"
                              @update:modelValue="
                                (val) => {
                                  agb = val
                                }
                              "
                            />
                            <u
                              ><a
                                class="link"
                                info
                                @click="tosModalActive = true"
                                style="cursor: pointer"
                              >
                                AGBs</a
                              ></u
                            >
                          </CCol>
                        </CRow>
                        <br />
                        <CRow>
                          <CCol class="text-center" xs="12">
                            <CButton
                              v-if="agb"
                              class="w-100"
                              color="success"
                              @click="buy()"
                              >Bestellen</CButton
                            >
                            <div
                              v-c-tooltip="{
                                content:
                                  'Bitte lesen Sie und nehmen Sie unsere AGBs an.',
                                placement: 'bottom',
                              }"
                            >
                              <CButton
                                v-if="!agb"
                                class="w-100"
                                color="success"
                                disabled
                                >Bestellen</CButton
                              >
                            </div>
                          </CCol>
                        </CRow>
                      </CContainer>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
    </CModal>
    <CModal
      fullscreen
      :visible="tosModalActive"
      @close="
        () => {
          tosModalActive = false
        }
      "
    >
      <CModalHeader>
        <CModalTitle
          >Allgemeine Geschäftsbedingungen (AGB) Swiss Digital Shipping
          Solutions</CModalTitle
        >
      </CModalHeader>
      <CModalBody>
        <div class="w-100 p-2 pt-5">
          <h1 class="p-2 text-center">
            Allgemeine Geschäftsbedingungen (AGB) Swiss Digital Shipping
            Solutions
          </h1>
          <ol>
            <li>
              <b>Geltungsbereich</b>
              <p>
                Innamorato Solutions ist ein Unternehmen mit Sitz in
                Niederlenz/AG. Es ist auf die Erbringung von Dienstleistungen
                aus dem Bereich ICT spezialisiert.
              </p>
              <p>
                Innamorato Solutions bietet Kunden die Möglichkeit an,
                Versandlabels der Post CH AG mit den bereits erfassten Daten aus
                dem ERP von bexio AG automatisiert zu erstellen. Das
                entsprechende Verfahren ist einfach und effizient und ermöglicht
                es dem Kunden, Zeit zu sparen.
              </p>
              <p>
                Die Dienstleistungen von Swiss Digital Shipping Solutions der
                Innamorato Solutions können mit allen auf dem Schweizer Markt
                erhältlichen Browser fähigen Geräten, die auf den Kunden
                registriert sind, genutzt werden.
              </p>
              <p>
                Der technische Zugang zu Swiss Digital Shipping Solutions
                erfolgt mittels des Internets und dem Link spl.inn-solutions.ch
                über Browser fähige Geräte als persönlichem Terminal des Kunden.
                Ist die Internetverbindung nicht garantiert bzw. die
                Schnittstelle zu Die Post AG CH bzw. bexio AG unterbrochen, so
                kann die Dienstleistung vorübergehend nicht genutzt werden.
              </p>
              <p>
                Mit der Registrierung über den Link spl.inn-solutions.ch bzw.
                beim Bestellvorgang über diesen Link anerkennt der Kunde die
                vorliegenden AGBs in ihrer jeweils geltenden Version, die auf
                der Website von Innamorato Solutions bzw. in der App unter der
                Bestellmaske abrufbar ist.
              </p>
              <p>
                Funktionen können aufgrund regulatorischer Vorgaben durch die
                Innamorato Solutions eingeschränkt werden.
              </p>
              <p>
                Die Versandlabels Swiss Digital Shipping Solutions dienen nur
                der Adressierung von Sendungen, beinhalten aber keine Frankatur
                und auch keine Steuern oder anderweitigen Angaben. Der Kunde ist
                sich bewusst, dass er sich betreffend Frankatur bzw. Steuern
                oder anderweitige Angaben selber kundig machen und für diese
                besorgt sein muss.
              </p>
            </li>
            <li>
              <b>Zugang zu Swiss Digital Shipping Solutions</b>
              <p>
                Der Zugang zu Swiss Digital Shipping Solutions setzt eine
                Einbindung in die Schnittstellen der Die Post CH AG sowie von
                bexio AG voraus.
              </p>
              <ol>
                <li>
                  <b>Rechnungsbeziehung mit der Post CH AG</b>
                  <p>
                    Der Kunde erklärt, über eine Rechnungsbeziehung mit der Post
                    CH AG zu verfügen.
                  </p>
                </li>
                <li>
                  <b>Frankierlizenz der Post CH AG und API-Zugang</b>
                  <p>
                    Der Kunde erklärt, über einen Barcode-API Zugang der Post CH
                    AG zu verfügen.
                  </p>
                </li>
                <li>
                  <b>API-Zugang zu bexio AG</b>
                  <p>
                    Der Kunde erklärt schliesslich, dass er über einen
                    API-Zugang zu bexio AG verfügt.
                  </p>
                </li>
              </ol>
              <p>
                Innamorato Solutions hat keinen Einfluss darauf, ob die Post CH
                AG bzw. bexio AG dem Kunden diese Zugänge verschafft, noch
                haftet Innamorato Solutions für die Dienstleistungen von die
                Post CH AG bzw. bexio AG (vgl. Ziff. 5 nachstehend):
              </p>
            </li>
            <li>
              <b>Sorgfaltspflichten des Kunden</b>
              <p>
                Im Umgang mit Swiss Post Label setzt folgende Sorgfaltspflichten
                des Kunden voraus:
              </p>
              <ul>
                <li>
                  Schutz der Browser fähigen Geräte vor unberechtigtem Gebrauch
                  durch unbefugte Dritte
                </li>
                <li>
                  Codes sind geheim zu halten und separat von Browser fähiges
                  Gerät aufzubewahren
                </li>
                <li>
                  Der gewählte Code darf nicht aus leicht zu ermittelnden
                  Kombinationen bestehen
                </li>
                <li>
                  Im Schadenfall hat der Kunde Innamorato Solutions bei der
                  Aufklärung des Falles und der Schadensminderung zu
                  unterstützen. Bei mutmasslich strafbaren Handlungen hat er
                  Strafanzeige bei der Polizei zu machen.
                </li>
                <li>
                  Bei der Installation der Swiss Digital Shipping Solutions App
                  auf dem Browser fähigen Gerät bestätigt der Kunden, dessen
                  rechtmässige Nutzer und Eigentümer zu sein. Der Kunde ist für
                  die Verwendung der Swiss Post Labels App auf seinem Browser
                  fähigen Gerät verantwortlich. Der Kunde trägt demzufolge
                  sämtliche
                </li>
                <li>
                  Bei der Installation der Swiss Digital Shipping Solutions App
                  auf dem Browser fähigen Gerät bestätigt der Kunden, dessen
                  rechtmässige Nutzer und Eigentümer zu sein. Der Kunde ist für
                  die Verwendung der Swiss Post Labels App auf seinem Browser
                  fähigen Gerät verantwortlich. Der Kunde trägt demzufolge
                  sämtliche Konsequenzen, die sich aus der Verwendung von Swiss
                  Digital Shipping Solutions auf seinem Browser fähigen Gerät
                  ergeben.
                </li>
                <li>
                  Der Kunde hält die standardmässigen Sicherheitsvorkehrungen im
                  Umgang mit seinen Codes und Passwörtern für Swiss Digital
                  Shipping Solutions ein. Stellt der Kunde fest, dass sich ein
                  unberechtigter Dritter Zugang zu diesen Angaben verschafft
                  hat, meldet er dies unverzüglich Innamorato Solutions (vgl.
                  nachstehend in Ziff. 8.5. unter Kontakt).
                </li>
                <li>
                  Der Kunde hat vor jedem Gebrauch zu prüfen, dass die im ERP
                  von bexio AG erfasste Adresse des Adressaten des Kunden
                  korrekt, vollständig und aktuell ist.
                </li>
                <li>
                  Der Kunde meldet von sich aus Innamorato Solutions jegliche
                  Änderungen zu seinen Koordinaten und stellt sicher, dass er
                  für Innamorato Solutions erreichbar bleibt. Innamorato
                  Solutions ist berechtigt, kontaktlose Geschäftsbeziehungen
                  aufzulösen.
                </li>
              </ul>
            </li>
            <li>
              <b>Missbräuche</b>
              <p>
                Stellt Innamorato Solutions oder bexio AG fest, dass das
                Nutzungsverhalten des Kunden vom bestimmungsgemässen Zweck
                abweicht oder besteht ein Verdacht auf vertrags- oder
                rechtswidriges Verhalten des Kunden, so ist Innamorato Solutions
                ermächtigt, den Kunden zur vertrags- bzw. rechtskonformen
                Verwendung von Swiss Post Labels anzuhalten, die seitens
                Innamorato Solutions zu erbringenden Leistungen ohne Abmahnung
                einzustellen, zu ändern oder einzuschränken, den Nutzungsvertrag
                mit dem Kunden fristlos und ohne Entschädigungsfolge für
                Innamorato Solutions zu kündigen bzw. bei eingetretenem Schaden,
                der durch das Handeln oder Unterlassen des Kunden entstanden
                ist, Schadenersetz zu verlangen bzw. die Freistellung von
                Ansprüchen Dritter.
              </p>
            </li>
            <li>
              <b>Haftung</b>
              <p>
                Sollte Innamorato Solutions die in den AGB stipulierten
                Pflichten verletzten, so haftet sie für die vom Kunden
                nachgewiesenen Schäden im nachstehend aufgeführten Umfang,
                sofern Innamorato Solutions nicht nachweist, dass sie kein
                Verschulden trifft.
              </p>
              <p>
                Die Haftung für leichte Fahrlässigkeit ist ausgeschlossen.
                Innamorato Solutions ersetzt Sach- und Vermögensschäden pro
                Schadenereignis im Umfang von CHF 1'000. Die Haftung der
                Innamorato Solutions für allfällige Folgeschäden, entgangenen
                Gewinn, Datenverlust ist, soweit gesetzlich zulässig,
                ausgeschlossen.
              </p>
              <p>
                Der technische Zugang zu den Dienstleistungen der Innamorato
                Solutions – via die Post CH AG bzw. bexio AG – ist Sache des
                Kunden. Die Innamorato Solutions übernimmt hierfür keine
                Haftung. Ebenso wenig übernimmt sie Haftung für den
                Netzbetreiber bzw. für die zur Verwendung der Dienstleistung der
                Innamorato Solutions notwendige Soft- bzw. Hardware.
              </p>
              <p>
                Die Innamorato Solutions ist bestrebt, einen möglichst störungs-
                und unterbruchfreien Zugang betreffend Swiss Digital Shipping
                Solutions zu ermöglichen. Die Innamorato Solutions kann dies
                aber nicht zu jeder Zeit gewährleisten.
              </p>
              <p>
                Solange Innamorato Solutions die ihr obliegende Sorgfalt
                wahrnimmt, trägt der Kunde einen allfälligen Schaden, der durch
                solche Unterbrüche entstehen kann.
              </p>
            </li>
            <li>
              <b>Entgelt</b>
              <p>
                Der Kunde bezahlt der Innamorato Solutions einen Preis für die
                Nutzung der Swiss Digital Shipping Solutions Dienstleistungen.
                Die Preisgestaltung richtet sich nach Volumina und
                Funktionalität. Es stehen verschiedene Abonnemente zur
                Verfügung. Die jeweils gültigen Preise sind unter Swiss Digital
                Shipping Solutions (inn-solutions.ch) «Preise» abrufbar.
              </p>
            </li>
            <li>
              <b>Elektronische Kommunikation</b>
              <p>
                Die Kommunikation zwischen Innamorato Solutions und dem Kunden
                erfolgt grundsätzlich über die Swiss Digital Shipping Solutions
                App.
              </p>
              <p>
                Innamorato Solutions darf den Kunden via E-Mail und Telefon
                kontaktieren, sofern er eine solche hinterlegt hat. Der Kunde
                trägt die Verantwortung für die Richtigkeit, Vollständigkeit und
                Aktualität seiner Kontaktangaben.
              </p>
              <p>
                Der Kunde ist sich bewusst, dass elektronische Kommunikation
                weder absolut vertraulich noch sicher ist. Dritte könnten diese
                einsehen, abfangen oder verändern oder sie kann verloren gehen.
              </p>
              <p>
                Sofern der Kunde Innamorato Solutions anschreibt oder Aufträge
                oder Instruktionen erteilt, welche nicht ausdrücklich von
                Innamorato Solutions bestätigt werden, muss davon ausgehen, dass
                sie nicht bei Innamorato Solutions eingegangen sind.
              </p>
              <p>
                Innamorato Solutions übernimmt keine Haftung für die Schäden,
                welche durch elektronische versandte E-Mails entstanden sind.
              </p>
            </li>
            <li>
              <b>Datenschutz</b>
              <ol>
                <li>
                  <b
                    >Sammlung und Nutzung von Daten zur Verbesserung von Swiss
                    Post Labels</b
                  >
                  <p>
                    Innamorato Solutions nutzt Daten für die Bereitstellung und
                    Verbesserung von Swiss Digital Shipping Solutions.
                    Innamorato Solutions gibt diese Daten ohne vorgängige
                    Einwilligung des Kunden nicht an Dritte weiter, sondern
                    verwendet sie ausschliesslich zur Verbesserung von Swiss
                    Digital Shipping Solutions.
                  </p>
                </li>
                <li>
                  <b>Cookies</b>
                  <p>
                    Innamorato Solutions nutzt in der Swiss Digital Shipping
                    Solutions App lediglich technische Cookies sowie local
                    storages.
                  </p>
                </li>
                <li>
                  <b>Aufbewahrung und Löschung</b>
                  <p>
                    Die bei Innamorato Solutions gespeicherten personenbezogenen
                    Daten des Kunden werden nach einem Jahr gelöscht bzw.
                    anonymisiert, wenn sie zur Durchführung der Dienstleistung
                    nicht mehr benötigt werden. <br />
                    Das erstelle Swiss Post Label (Bilddatei) wird nicht
                    gespeichert.
                  </p>
                </li>
                <li>
                  <b>Hosting ausschliesslich in der Schweiz</b>
                  <p>
                    Innamorato Solutions arbeitet ausschliesslich mit Schweizer
                    Hosts zusammen.
                  </p>
                </li>
                <li>
                  <b>Auskunft- und Informationsrechte</b>
                  <p>
                    Innamorato Solutions ist für die Frage zur Bearbeitung von
                    Daten unter folgende Adresse erreichbar: <br />
                    <a href="mailto:legal@inn-solutions.ch"
                      >legal@inn-solutions.ch</a
                    >
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <b>Geistiges Eigentum</b>
              <p>
                Für die Dauer des jeweils abgeschlossenen Abonnements erhält der
                Kunde das unübertragbare, nicht-exklusive Recht zur Nutzung der
                Swiss Digital Shipping Solutions App. Das damit verbundene Recht
                ergibt sich aus den vorliegenden AGB.
              </p>
              <p>
                Alle Immaterialgüterrechte verblieben bei der Innamorato
                Solutions bzw. allfällig berechtigten Dritten. Für Open Source
                Lizenzen gelten die gleichen Lizenzen auch bei einer allfälligen
                Weiterverwendung durch den Kunden.
              </p>
              <p>
                Verletzt der Kunde diese Immaterialgüterrechte und wird
                Innamorato Solutions dafür in Anspruch genommen bzw. kommt
                Innamorato Solutions dabei zu Schaden, so verpflichtet sich der
                Kunde, Innamorato Solutions freizuhalten.
              </p>
            </li>
            <li>
              <b>Dauer</b>
              <p>
                Der vorliegende Vertrag wird auf unbestimmte Zeit abgeschlossen.
              </p>
            </li>
            <li>
              <b>Kündigung</b>
              <p>
                Der Kunde kann die App auf seinem Browser fähigen Gerät ohne
                weiteres löschen bzw. beide Vertragspartner können die
                Vertragsbeziehung jederzeit ohne Angaben von Gründen schriftlich
                kündigen.
              </p>
              <p>
                Die Kündigung seitens Innamorato Solutions erfolgt gültig, wenn
                sie an die vom Kunden zuletzt bekannt gegebene Adresse erfolgt.
              </p>
            </li>
            <li>
              <b>Sperrung der Zugänge durch den Kunden</b>
              <p>
                Der Kunde kann die Sperrung seines Zugang zu Swiss Digital
                Shipping Solutions (
                <a href="mailto:legal@inn-solutions.ch"
                  >legal@inn-solutions.ch</a
                >
                ) bei Innamorato Solutions beantragen.
              </p>
              <p>
                Die im Zeitpunkt der Beantragung der Sperre bereits ausgelösten
                Swiss Digital Shipping Solutions Dienstleistungen gelten als
                beansprucht und können nicht rückgängig gemacht werden.
              </p>
            </li>
            <li>
              <b
                >Änderungen der Dienstleistungen bzw. Sperrung des Zugangs durch
                Innamorato Solutions</b
              >
              <p>
                Innamorato Solutions kann seine Dienstleistungen betreffend
                Swiss Post Labels jederzeit ändern, aktualisieren,
                weiterentwickeln oder einstellen.
              </p>
              <p>
                Ebenso kann Innamorato Solutions aus technischen oder
                rechtlichen Gründen die Verfügbarkeit einschränken oder den
                Zugang gänzlich unterbinden.
              </p>
            </li>
            <li>
              <b>Beizug Dritter</b>
              <p>
                Der Kunde ist ausdrücklich damit einverstanden, dass Innamorato
                Solutions Dritte als Dienstleister beiziehen darf und dass dabei
                die Daten des Kunden, soweit erforderlich, an diese Dritte
                weitergegeben werden dürfen. Innamorato Solutions wendet bei
                diesem Beizug von Dritten die notwendige Sorgfalt in der
                Auswahl, Instruktion und Überwachung der Dritten an.
              </p>
            </li>
            <li>
              <b>Unabhängige Parteien</b>
              <p>
                Innamorato Solutions, Die Post CH AG, bexio AG und allfällige
                Dritte sind unabhängige Vertragsparteien. Keine Regelung dieser
                Vereinbarung beabsichtigt die Begründung eines
                arbeitsrechtlichen Verhältnisses, eines Agenturverhältnisses,
                eines Joint Ventures, einer gesellschaftsrechtlichen oder einer
                treuhänderischen Verbindung zwischen den Parteien.
              </p>
              <p>
                Weder einer der Parteien noch den Mitgliedern ihrer Organe,
                ihren Mitarbeitern und Agenten steht das Recht zu, im Namen oder
                auf Rechnung der anderen Partei zu handeln. Insbesondere steht
                keiner Partei oder einer dieser Personen das Recht zu,
                Verpflichtungen zu Lasten der anderen Partei einzugehen oder
                Versprechungen, Garantien und sonstige Erklärungen in deren
                Namen abzugeben.
              </p>
            </li>
            <li>
              <b>Salvatorische Klausel</b>
              <p>
                Sollte einer dieser Bestimmungen der AGB ungültig oder
                teilungültig sein, wird dadurch die Gültigkeit der anderen
                Bestimmungen nicht berührt.
              </p>
              <p>
                Innamorato Solutions und der Kunden verpflichten sich für diesen
                Fall, die unwirksame Bestimmung durch eine ihrem Sinn und Zweck
                weitmöglichst gleichwertige Bestimmung zu ersetzen
              </p>
            </li>
            <li>
              <b>Anwendbares Recht</b>
              <p>
                Die Rechtsbeziehung zwingen Innamorato Solutions und dem Kunden
                untersteht ausschliesslich dem materiellen schweizerischen Recht
                mit ausdrücklichem Ausschluss von Kollisionsrecht und
                Staatsverträgen.
              </p>
            </li>
            <li>
              <b>Gerichtsstand</b>
              <p>
                Ausschliesslicher Gerichtsstand für alle Verfahrensarten ist
                <b>Lenzburg</b>, vorbehältlich anderweitiger zwingender
                Bestimmungen.
              </p>
            </li>
          </ol>
          <p style="font-size: small">
            ©2023 Innamorato Solutions, alle Rechte vorbehalten
          </p>
        </div></CModalBody
      >
      <CModalFooter>
        <CContainer fluid>
          <CRow>
            <CCol xs="12"
              ><CButton
                class="w-100 text-center"
                color="success"
                @click="
                  ;(agb = true),
                    (isModalActive = true),
                    (tosModalActive = false)
                "
                >AGBs annehmen</CButton
              ></CCol
            >
          </CRow>
        </CContainer>
      </CModalFooter>
    </CModal>
  </CContainer>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions, mapGetters } from 'vuex'
import { cilCheckCircle } from '@coreui/icons'
import { storeService } from '@/services/storeServices'

export default {
  name: 'company',
  data() {
    return {
      showAlert: false,
      cilCheckCircle,
      subscriptionLoading: true,
      purchaseLoading: false,
      purchasedSuccessful: false,
      isModalActive: false,
      tosModalActive: false,
      isYearlyModel: false,
      isSchoolSession: false,
      isSetupService: false,
      priceS: 0,
      discount: 0,
      agb: false,
      chSblModel: {},
      services: {},
      subscriptionPrice: 0,
      subscriptionName: 'Gratis',
      servicesPrice: 0,
      subscription: 1,
      address: {
        name: '',
        street: '',
        city: '',
        postalcode: '',
        country: '',
        abwAddr: false,
        num: '',
        email: '',
      },
      loadedModules: [],
      mMI: 0,
      showSalesEMail: false,
    }
  },
  // watch: {
  //   gModules(oldModules, loadedModules) {
  //     console.log(loadedModules)
  //   },
  // },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapState('company', ['company']),
    ...mapState('store', ['store']),
    ...mapGetters('store', ['gModules']),
  },
  async mounted() {
    this.subscriptionLoading = true
    await this.getModules(this.user.token)
      .then(() => {
        console.log('gModules', this.gModules)
        this.subscriptionLoading = false
      })
      .catch((err) => {
        console.log('err loading modules', err)
        this.subscriptionLoading = true
      })
    setTimeout(() => {
      this.showSalesEMail = true
    }, 7500)
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('company', [
      'getCompanyModules',
      'getCompanyCurrentPeriods',
      'logout',
    ]),
    ...mapActions('store', ['getModules']),
    ...mapActions(['toggleStore']),
    forceRerender() {
      location.reload()
    },
    async buy() {
      this.purchaseLoading = true
      await storeService
        .bookSubscription(
          this.user.token,
          this.company.company._id,
          this.gModules[this.mMI]._id,
          this.chSblModel[this.subscription],
          this.priceS,
          this.discount,
          this.isYearlyModel,
          this.gModules[this.mMI].services,
          this.subscriptionPrice,
        )
        .then((res) => {
          console.log(res)
          this.purchaseLoading = false
          this.purchasedSuccessful = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setYearly(val) {
      this.isYearlyModel = val
    },
    calculatePriceS(val) {
      const bp = this.chSblModel[val].price
      this.subscriptionName = this.chSblModel[val].name
      let newP = 0
      let sP = 0
      this.isYearlyModel ? (this.discount = 5) : (this.discount = 0)
      this.isYearlyModel ? (newP = bp * 12 * 0.95) : (newP = bp)
      this.subscriptionPrice = newP.toFixed(2)
      for (let i = 0; i < this.gModules[this.mMI].services.length; i++) {
        this.gModules[this.mMI].services[i].selected
          ? (newP += 100)
          : (newP += 0)
        this.gModules[this.mMI].services[i].selected ? (sP += 100) : (sP += 0)
      }
      this.priceS = newP.toFixed(2)
      this.servicesPrice = sP
    },
    test(val) {
      console.log(val)
    },
    checkSelectedServices(services) {
      for (let i = 0; i < services.length; i++) {
        if (services[i].selected) {
          return true
        }
      }
      return false
    },
    validateEmail(e) {
      var rx =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      console.log('validateEmail', e, String(e).match(rx))
      return String(e).match(rx)
    },
  },
}
</script>

<style scoped>
.successIcon {
  width: 6rem;
  height: 6rem;
  color: white;
}
@media (min-width: 576px) {
  .successIcon {
    width: 7rem;
    height: 7rem;
  }
}

@media (min-width: 768px) {
  .successIcon {
    width: 9rem;
    height: 9rem;
  }
}

@media (min-width: 992px) {
  .successIcon {
    width: 15rem;
    height: 15rem;
  }
}

@media (min-width: 1200px) {
  .successIcon {
    width: 17.5rem;
    height: 17.5rem;
  }
}

@media (min-width: 1400px) {
  .successIcon {
    width: 20rem;
    height: 20rem;
  }
}

ol {
  counter-reset: item;
}
ul {
  counter-reset: none;
}
ol li {
  display: block;
}
ul li {
  display: list-item;
}
ol li:before {
  content: counters(item, '. ') '. ';
  counter-increment: item;
}

ul li:before {
  content: initial;
}
</style>
