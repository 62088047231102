/* eslint-disable */
import { config } from '@/config'
import { userService } from '@/services/services'
import { settingsServices } from '@/services/settingsServices'
import { innSolutionsServices } from './innSolutionsServices'
import store from '../store';
var axios = require('axios')

export const companyService = {
	saveCompany,
	getCompany,
	getCompanys,
	getSSOCompany,
	setDefaultModules,
	setCompanyDefaultModuleSetting,
	getCompanyModules,
	getCompanyCurrentPeriods,
	getCompanyAllPeriods,
}

axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error?.status === 401) {
		userService.logout()
	}
	if (error.response.status === 401) {
		userService.logout()
	}
	return Promise.reject(error);
});

async function getCompanyModules(jwt, companyID, modules) {
	return await Promise.all(
		modules.map(async (e) => {
			return axios({
				method: 'GET',
				url: `${innSolutionsServices.getAPIUrl()}/company/module`,
				headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
				params: {
					companyID: companyID,
					moduleID: e,
				},
			})
				.then((res) => {
					return res.data
				})
				.catch((err) => {
					console.log('ERROR MODULES', err);
					console.log('getCompanyModulesErr', err.response)
					return err
				})
		})
	)
		.then(res => {
			return res.map(e => {
				return e.data.module
			})
		})
		.catch(err => {
			console.log('err', err)
			return err
		})
}
async function getCompanyCurrentPeriods(jwt, companyID) {
	return axios({
		method: 'GET',
		url: `${innSolutionsServices.getAPIUrl()}/store/currentPeriods`,
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
		params: {
			companyID: companyID,
		},
	})
		.then((res) => { return res.data })
		.catch((err) => { return err })
}

async function getCompanyAllPeriods(jwt, companyID) {
	return axios({
		method: 'GET',
		url: `${innSolutionsServices.getAPIUrl()}/store/allPeriods`,
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
		params: {
			companyID: companyID,
		},
	})
		.then((res) => { return res.data })
		.catch((err) => { return err })
}

function saveCompany(token, company) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${token}` },
		body: JSON.stringify(company),
	}
	return fetch(`${config.apiUrl}company/saveCompany`, requestOptions)
		.then(handleResponse)
		.then((company) => {
			return company
		})
}

async function setCompanyDefaultModuleSetting(jwt, companyID, modules, cAddress) {
	console.log('setCompanyDefaultSetting')
	settingsServices.sDefaultModuleSettings(
		jwt,
		modules,
		companyID,
		cAddress
	)
		.then(res => {
			console.log(res)
			return err
		})
		.catch(err => {
			console.log(err)
			return err
		})
}

async function getSSOCompany(jwt, bxToken, bxCompany_id, bxLogin_id) {
	return await axios({
		method: 'POST',
		url: `${innSolutionsServices.getAPIUrl()}/company/getSSOCompany`,
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
		data: { bxCompany_id: bxCompany_id, bxToken: bxToken, bxLogin_id: bxLogin_id }
	})
		.then(res => {
			// console.log('getSSOCompany33', res);
			if (res.data.success) {
				return res.data.data
			}
			Promise.reject(res.err)
		})
		.catch(err => { return err })
}
async function setDefaultModules(jwt, companyID) {
	return await axios({
		method: 'POST',
		url: `${innSolutionsServices.getAPIUrl()}/store/setDefaultModules`,
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
		data: { companyID: companyID }
	})
		.then(res => {
			if (res.data.success) {
				return res.data.company
			}
			console.log(res.err)
			Promise.reject(res.err)
		})
		.catch(err => { return err })
}

function getCompany(token, companyID) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${token}` },
		body: JSON.stringify({ companyID: companyID }),

	}
	console.log('requestOptions', requestOptions)
	return fetch(`${innSolutionsServices.getAPIUrl()}/company`, requestOptions)
		.then(handleResponse)
		.then((company) => {
			return company
		})
}

async function getCompanys(token, companyIDs) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${token}` },
		body: JSON.stringify(companyIDs),

	}
	return fetch(`${innSolutionsServices.getAPIUrl()}/company/getCompanys`, requestOptions)
		.then(handleResponse)
		.then((companys) => {
			return companys
		})
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text)
		if (!response.ok) {
			console.log('response isnt okay')
			if (response.status === 401) {
				console.log('401')
				// auto logout if 401 response returned from api
				userService.logout();
			}

			const error = (data && data.message) || response.statusText
			return Promise.reject(error)
		}

		return data
	})
}
