/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupI18n } from './i18n'
import { setI18nLanguage, loadLocaleMessages } from './i18n'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

const i18n = setupI18n()
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(i18n)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('QuillEditor', QuillEditor)
app.mount('#app')
app.config.globalProperties.$loadLocaleMessages = loadLocaleMessages
app.config.globalProperties.$setI18nLanguage = setI18nLanguage
