<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
      <!-- <CAvatar :src="avatar" size="md" /> {{ user.name + ' ' + user.surname }} -->
      {{ user.name + ' ' + user.surname }}
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <!-- <CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info-gradient" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem> -->
      <!-- <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader> -->
      <!-- <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem> -->
      <CDropdownItem @click="openAccountSettings()">
        <CIcon icon="cil-settings" /> Settings
      </CDropdownItem>
      <!-- <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem> -->
      <!-- <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem> -->
      <CDropdownDivider />
      <!-- <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem> -->
      <CDropdownItem @click="logout()">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
      showAlert: false,
    }
  },
  computed: {
    ...mapState('account', ['status', 'user']),
  },
  async mounted() {},
  methods: {
    ...mapActions('account', ['logout']),
    test() {
      console.log(this.$store.state)
    },
    openAccountSettings() {
      console.log('open settings')
      this.$router.push('/accountSettings')
    },
  },
}
</script>
