import { createI18n } from 'vue-i18n'

export const SUPPORT_LOCALES = ['de', 'it', 'fr', 'en']

export function setupI18n(options = { locale: 'de' }) {
  const i18n = createI18n({
    locale: options.locale, // set locale
    fallbackLocale: 'en',
    messages: {}, // set locale messages
  })
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  i18n.locale = locale
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  )

  // set locale and locale message
  i18n.setLocaleMessage(locale, messages.default)
}
