/* eslint-disable */
import { companyService } from '@/services/companyServices'
var luxon = require('luxon')
const { DateTime } = require('luxon')

const state = { company: null }

const actions = {
  saveCompany({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      companyService
        .saveCompany(payload.token, payload.company)
        .then((company) => {
          commit('saveCompany', { company })
          return resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  async getCompany({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      companyService
        .getCompany(payload.token, payload.companyID)
        .then((company) => {
          commit('saveCompany', { company })
          resolve()
        })
        .catch((err) => {
          reject()
        })
    })
  },
  async getSSOCompany({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      companyService
        .getSSOCompany(
          payload.jwt,
          payload.bxToken,
          payload.bxCompany_id,
          payload.bxLogin_id,
        )
        .then((res) => {
          commit('saveCompany', { company: res.company })
          resolve()
        })
        .catch((err) => {
          console.log(err)
          reject()
        })
    })
  },
  async setSSOCompanyDefaultModules({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      companyService
        .setDefaultModules(payload.jwt, payload.companyID)
        .then(async () => {
          resolve()
        })
        .catch((err) => {
          reject()
        })
    })
  },
  /**
   *
   * @param {Object} payload // { periods, moduleID }
   * @returns
   */
  checkIfPeriodNotOverdue({ dispatch, commit }, payload) {
    var check = false
    for (let i = 0; i < payload.periods.length; i++) {
      if (payload.moduleID === payload.periods[i].moduleID) {
        if (
          payload.periods[i].isActive &&
          checkPeriodNotOverdue(payload.periods[i])
        ) {
          check = true
        } else {
          check = false
        }
      }
    }
    return check
  },
  async getCompanyModules({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await companyService
        .getCompanyModules(payload.jwt, payload.companyID, payload.modules)
        .then(async (modules) => {
          commit('saveCompanyModules', { modules: modules })
          resolve()
        })
        .catch((err) => {
          reject()
        })
    })
  },
  async getCompanyCurrentPeriods({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await companyService
        .getCompanyCurrentPeriods(payload.jwt, payload.companyID)
        .then(async (periods) => {
          if (periods.success) {
            commit('saveCompanyPeriods', { periods: periods.data })
            resolve()
          } else {
            reject()
          }
        })
        .catch((err) => {
          reject()
        })
    })
  },
  async getCurrentPeriod({ dispatch, commit }, payload) {
    for (let i = 0; i < payload.periods.length; i++) {
      if (payload.periods[i].moduleID === payload.moduleID) {
        const time = DateTime.local().toMillis()
        const periodEnd = DateTime.fromMillis(payload.periods[i].endDate).endOf(
          'day',
        )
        const periodStart = DateTime.fromMillis(
          payload.periods[i].startDate,
        ).startOf('day')
        if (periodStart <= time && time <= periodEnd) {
          return payload.periods[i]
        }
      }
    }
  },
  async setCompanyDefaultModuleSettings({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      companyService
        .setCompanyDefaultModuleSetting(
          payload.jwt,
          payload.companyID,
          payload.modules,
          payload.cAddress,
        )
        .then(async () => {
          resolve()
        })
        .catch((err) => {
          reject()
        })
    })
  },
  getCompanys({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      companyService
        .getCompanys(payload.token, payload.companyIDs)
        .then((companys) => {
          return resolve(companys)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  },
  setCompany({ dispatch, commit }, company) {
    commit('setCompany', { company })
  },
  setCompanyLogo({ dispatch, commit }, base64) {
    commit('setCompanyLogo', { base64 })
  },
  activateCompany({ dispatch, commit }, val) {
    commit('activateCompany', { val })
  },
}

const getters = {
  company(state) {
    return state.company
  },
}

const mutations = {
  saveCompany(state, company) {
    state.company = company
    state.isActive = false
    state.company.companyModules = []
    state.company.periods = []
  },
  setCompany(state, company) {
    state = company
    state.isActive = true
  },
  setCompanyLogo(state, base64) {
    state.company.logo_base64 = base64
  },
  activateCompany(state, val) {
    state.company.isActive = val
  },
  saveCompanyModules(state, modules) {
    state.company.companyModules = modules.modules
  },
  saveCompanyPeriods(state, periods) {
    state.company.periods = periods.periods
  },
}

function checkPeriodNotOverdue(period) {
  var periodDate = new Date(period.endDate)
  var td = getTodayInMs()
  return td.getTime() - periodDate.getTime() < 1209600000
}

function getTodayInMs() {
  const hh = new Date(Date.now())
  const h = new Date(hh.setHours(0))
  const m = new Date(h.setMinutes(0))
  const s = new Date(m.setSeconds(0))
  const ms = new Date(s.setMilliseconds(0))
  var td = new Date(ms)
  return td
}

export const company = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
