/* eslint-disable */
import router from '@/router'
import { innSolutionsServices } from './innSolutionsServices'

var axios = require('axios')

export const userService = {
  login,
  logout,
  getUser,
  bxInitAPI,
  ssobxInitAPI,
  assignCompanyToUser,
  updateUserProfile,
  ssoLogin,
  ssoGetUser,
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.status === 401) {
    userService.logout()
  }
  if (error.response.status === 401) {
    userService.logout()
  }
  return Promise.reject(error);
});

function login(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }
  return fetch(`${innSolutionsServices.getAPIUrl()}/auth/login/`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      // login successful if there's a jwt token in the response
      // if (res.token) {
      //   // store user details and jwt token in local storage to keep user logged in between page refreshes
      //   localStorage.setItem('token', JSON.stringify(res.token))
      // }
      return res.token
    })
}

async function ssoLogin(bxToken, login_id, company_id, user_id, company_user_id) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/auth/bxSSOLogin`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      bxToken: bxToken, bxLogin_id: login_id, bxCompany_id: company_id,
      user_id: user_id,
      company_user_id: company_user_id,
    }
  })
    .then(res => {
      return res.data.token
    })
    .catch(err => { return err })
}

async function ssoGetUser(jwt, bxUser_id) {
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/account/bxSSO`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: {
      bxUser_id: bxUser_id,
    }
  })
    .then(res => {
      return res.data
    })
    .catch(err => { return err })
}

async function ssobxInitAPI() {
  return await axios({
    method: 'GET',
    url: `${innSolutionsServices.getAPIUrl()}/authSSO/bxInitAPI`,
    headers: { 'Content-Type': 'application/json' },
  })
    .then(res => {
      window.open(res.data.url, '_self')
      return
    })
    .catch(err => { return err })
}

async function assignCompanyToUser(jwt, companyID) {
  console.log('companyID', companyID)
  return await axios({
    method: 'POST',
    url: `${innSolutionsServices.getAPIUrl()}/account/assignToCompany`,
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${jwt}` },
    data: { companyID: companyID },
  })
    .then(res => {
      if (res.data.success) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    })
    .catch(err => { return err })
}

function bxInitAPI(token) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${token}` },
  }
  return fetch(`${innSolutionsServices.getAPIUrl()}/bxAPI/initAPI`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res.url
      // setTimeout(() => {
      //   // console.log(res);

      // }, 1000000)
      // login successful if there's a jwt token in the response
    })
}
function getUser(token, user) {
  console.log('getUser')
  const requestOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return fetch(`${innSolutionsServices.getAPIUrl()}/account`,
    requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return user
      }

      return Promise.reject({})
    })
}

/**
 * 
 * @param {String} jwt 
 * @param {String} userID 
 * @param {String} email 
 * @param {String} name 
 * @param {String} surname 
 * @param {String} AddrTitle 
 * @returns 
 */
async function updateUserProfile(jwt, userID, email, name, surname, AddrTitle, language) {
  console.log(jwt, userID, email, name, surname, AddrTitle, language)
  return await axios({
    method: 'post',
    url: `${innSolutionsServices.getAPIUrl()}/account/updateUserProfile`,
    headers: { Authorization: `Bearer ${jwt}` },
    data: {
      userID: userID,
      updateVals: {
        email: email,
        name: name,
        surname: surname,
        AddrTitle: AddrTitle,
        language: language,
      },
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => { return err })
}

function logout() {
  console.log('logout')
  // remove user from local storage to log user out
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('company')
  router.push('/login')
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
        location.reload(true)
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
