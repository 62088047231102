<template>
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <div class="body flex-grow-1 p-0" :style="style">
      <CContainer
        v-if="loadedPeriods"
        class="w-100 p-0"
        style="max-width: 100% !important"
      >
        <CRow class="w-100" style="margin: 0">
          <CCol
            class="p-0"
            v-for="(app, index) in apps"
            :class="{ active: isActive(index) }"
            :key="index"
            :aria-labelledby="app.arealabel"
            style="cursor: pointer"
          >
            <div class="h-100 w-100 text-nowrap">
              <CContainer class="w-100 text-nowrap">
                <CRow class="p-0 w-100 text-nowrap" style="margin: 0">
                  <CCol xs="11" class="p-0 text-nowrap hstack gap-2">
                    <div
                      class="text-nowrap w-100"
                      @click="
                        () => {
                          changeIndex(index)
                        }
                      "
                    >
                      {{ app.names[this.$i18n.locale] }}
                    </div>
                    <CCloseButton
                      class="text-nowrap"
                      style="width: 0.25em; height: 0.25em"
                      @click="
                        () => {
                          ;(app.isActive = false),
                            closeApp({ apps: apps, i: index })
                        }
                      "
                    />
                  </CCol>
                  <!-- <CCol xs="1">

                  </CCol> -->
                </CRow>
              </CContainer>
            </div>
          </CCol>
        </CRow>
        <CContainer class="w-100 p-0" style="max-width: 100% !important">
          <div
            v-for="(app, index) in apps"
            v-show="index === tabPaneActiveKey"
            :key="index"
          >
            <keep-alive v-if="app.isActive">
              <component
                v-if="app.isActive"
                v-bind:is="app.component"
                :key="`${app.component}${index}`"
                :moduleID="app.moduleID"
              ></component>
            </keep-alive>
          </div>
        </CContainer>
      </CContainer>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import { defineAsyncComponent } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppTabs',
  components: {
    Dashboard: defineAsyncComponent(() => import('@/views/Dashboard.vue')),
    //Administration
    SubscriptionManagement: defineAsyncComponent(() =>
      import('@/solutionssuite/Administration/subscriptionManagement.vue'),
    ),
    CustomerManagement: defineAsyncComponent(() =>
      import('@/solutionssuite/Administration/CustomerManagement.vue'),
    ),

    // Einkauf
    Bills: defineAsyncComponent(() =>
      import('@/solutionssuite/purchase/Bills.vue'),
    ),
    //Swiss Digital Shipping Solutions
    LabelGenInvoice: defineAsyncComponent(() =>
      import('@/sdss/LabelGenInvoice.vue'),
    ),
    LabelGenDeliveryNote: defineAsyncComponent(() =>
      import('@/sdss/LabelGenDeliveryNote.vue'),
    ),
    LabelGenOrder: defineAsyncComponent(() =>
      import('@/sdss/LabelGenOrder.vue'),
    ),
    LabelGenImport: defineAsyncComponent(() =>
      import('@/sdss/LabelGenImport.vue'),
    ),
    LabelGenSingel: defineAsyncComponent(() =>
      import('@/sdss/LabelGenSingel.vue'),
    ),
    Labels: defineAsyncComponent(() => import('@/sdss/Labels.vue')),
    SPLHelp: defineAsyncComponent(() => import('@/sdss/SPLHelp.vue')),
    SPLSettings: defineAsyncComponent(() => import('@/sdss/SPLSettings.vue')),
    EMailSettings: defineAsyncComponent(() => import('@/sdss/EMailSettings.vue')),

        //Swiss Digital Payment Solutions
    TwintConverter: defineAsyncComponent(() =>
      import('@/sdps/TwintConverter.vue'),
    ),
  },
  data() {
    return {
      loadingCompany: false,
      modals: false,
      style: '',
      loadedPeriods: false,
    }
  },
  computed: {
    ...mapGetters('tabs', ['apps', 'tabPaneActiveKey']),
    ...mapGetters(['sidebarVisible']),
    ...mapState('tabs', ['apps', 'tabPaneActiveKey']),
    ...mapState('account', ['status', 'user']),
    ...mapState('company', ['company']),
    ...mapState(['sidebarVisible']),
  },
  watch: {
    // eslint-disable-next-line
    sidebarVisible(oldVal, newVal) {
      newVal ? (this.style = '') : (this.style = 'margin-left: -18em')
    },
  },
  mounted() {
    this.style = this.sidebarVisible
      ? (this.style = 'margin-left: -18em')
      : (this.style = '')
    if (this.apps.length <= 0) {
      this.apps.push({
        name: 'Dashboard',
        names: {
          de: 'Dashboard',
          en: 'Dashboard',
          fr: 'Dashboard',
          it: 'Dashboard',
        },
        component: 'Dashboard',
        arealabel: 'dashboard-tag',
        isActive: true,
      })
    }
    this.$store.watch(
      (state) => state.company?.company.periods,
      async (value) => {
        if (value) {
          this.loadedPeriods = true
        }
      },
    )
  },
  methods: {
    ...mapActions('tabs', ['closeApp', 'changeIndex']),
    log(txt) {
      console.log(txt)
    },
    subtractFromKey(val) {
      this.tabPaneActiveKey = this.tabPaneActiveKey - val
    },
    isActive(i) {
      return this.tabPaneActiveKey === i
    },
  },
}
</script>

<style scoped>
.active {
  background-color: white;
  border: lightgray;
  border-style: solid;
  border-bottom-style: none;
  border-width: 0.1rem;
  border-radius: 10px 10px 0px 0px;
}
</style>
