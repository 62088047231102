<template>
  <CFooter style="min-height: 1.3rem !important">
    <div class="footerCustom">
      Innamorato Solutions Suite
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ms-auto footerCustom">
      <span class="me-1">Powered by Innamorato Solutions</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style scoped>
.footerCustom {
  font-size: 0.75rem;
  color: lightslategrey;
}
</style>
