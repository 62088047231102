<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <!-- custom-class-name="sidebar-brand-narrow" -->
    <CSidebarBrand style="background-color: white">
      <CImage
        custom-class-name="sidebar-brand-full"
        fluid
        :src="logo"
        style="max-height: 63px"
      />
      <!-- <CImage :src="logo" :height="35" /> -->
    </CSidebarBrand>
    <!-- <AppSidebarNav :user="0" /> -->
    <CSidebarNav>
      <div v-for="module in modules" :key="module">
        <div
          v-if="
            module.isActive &&
            module.isVisible &&
            checkAccess(module) &&
            checkSubscription(module) &&
            checkSubscriptionStatus(module)
          "
        >
          <CNavTitle>{{ module.names[$i18n.locale] }}</CNavTitle>
          <div v-for="route in module.routes" :key="route">
            <div v-if="checkAccess(route)">
              <CNavGroup>
                <template #togglerContent>{{
                  route.names[$i18n.locale]
                }}</template>
                <div v-for="view in route.views" :key="view">
                  <CNavItem
                    v-if="checkAccess(view)"
                    style="padding-left: 0rem !important"
                    href="#"
                    color="light"
                    variant="ghost"
                    @click="
                      openApp({
                        name: view.name,
                        names: view.names,
                        component: view.component,
                        arealabel: view.arealabel,
                        isActive: view.isActive,
                        moduleID: module._id,
                      })
                    "
                    ><CIcon
                      customClassName="nav-icon"
                      :icon="icon[view.icon]"
                    />{{ view.names[$i18n.locale] }}
                  </CNavItem>
                </div>
              </CNavGroup>
            </div>
          </div>
        </div>
      </div>
    </CSidebarNav>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleSidebar')"
    />
  </CSidebar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { CIcon } from '@coreui/icons-vue'
// import { AppSidebarNav } from './AppSidebarNav'
import {
  cilBarcode,
  cilSettings,
  cilDescription,
  cilSchool,
  cilApplications,
  cilCloudUpload,
  cilColorBorder,
} from '@coreui/icons'

export default {
  name: 'AppSidebar',
  components: {
    // AppSidebarNav,
    CIcon,
  },
  data() {
    return {
      icon: {
        cilBarcode,
        cilSettings,
        cilDescription,
        cilSchool,
        cilApplications,
        cilCloudUpload,
        cilColorBorder,
      },
      logo: '',
      modules: [],
      companyModules: [],
      periods: [],
    }
  },
  beforeMount() {},
  computed: {
    ...mapState('company', ['company']),
    ...mapState('account', ['user']),
    ...mapState('store', ['modules']),
    ...mapState(['sidebarUnfoldable', 'sidebarVisible']),
  },
  async mounted() {
    this.logo = `data:image/png;base64,${this.company.company.logo_base64}`
    this.$store.watch(
      (state) => state.store.modules,
      async (value) => {
        if (value) {
          this.modules = value
        }
      },
    )
    this.$store.watch(
      (state) => state.company?.company.logo_base64,
      (value) => {
        if (value) {
          console.log('updated base64 logo....')
          this.logo = `data:image/png;base64,${value.base64}`
        }
      },
    )
    this.$store.watch(
      (state) => state.company?.company.companyModules,
      async (value) => {
        if (value) {
          this.companyModules = value
        }
      },
    )
    this.$store.watch(
      (state) => state.company?.company.periods,
      async (value) => {
        if (value) {
          this.periods = value
        }
      },
    )
  },
  methods: {
    ...mapActions('tabs', ['openApp']),
    ...mapActions('store', ['getModules']),
    ...mapActions('company', ['getCompanyModules', 'getCompanyCurrentPeriods']),
    checkAccess(module) {
      for (let i = 0; i < module.access.length; i++) {
        if (this.user.role === module.access[i]) {
          return true
        }
      }
      return false
    },
    checkSubscription(module) {
      if (!module.needSubscription) {
        return true
      }
      for (let i = 0; i < this.companyModules.length; i++) {
        if (
          module._id === this.companyModules[i].moduleID &&
          this.companyModules[i].isActive
        ) {
          return true
        }
      }
      return false
    },
    checkSubscriptionStatus(module) {
      if (!module.needSubscription) {
        return true
      }
      var check = false
      for (let i = 0; i < this.periods.length; i++) {
        if (module._id === this.periods[i].moduleID) {
          if (
            this.periods[i].isActive &&
            this.checkIfPeriodIsOverdue(this.periods[i])
          ) {
            check = true
          } else {
            check = false
          }
        }
      }
      return check
    },
    checkIfPeriodIsOverdue(period) {
      var periodDate = new Date(period.endDate)
      const hh = new Date(Date.now())
      const h = new Date(hh.setHours(0))
      const m = new Date(h.setMinutes(0))
      const s = new Date(m.setSeconds(0))
      const ms = new Date(s.setMilliseconds(0))
      var td = new Date(ms)
      return td.getTime() - periodDate.getTime() < 1209600000
    },
    test() {
      console.log(this.$store.state)
    },
  },
}
</script>

<style scoped>
html:not([dir='rtl']) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 2rem !important;
}
</style>
